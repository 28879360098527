@charset "utf-8";



// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     1em;

$text-color:       #f1f1f1;
$background-color: #101010;
$highlight-color: lighten($background-color, 10%);
$brand-color:      #008020;

$grey-color:       #A2A2A2;
$grey-color-light: lighten($grey-color, 10%);
$grey-color-dark:  darken($grey-color, 25%);

$link-color: $brand-color;
$link-visited-color: mix($brand-color, $grey-color-dark, 50%);
$link-hover-color: $text-color;

// Width of the content area
$content-width:    50em;

$on-palm:          35em;
$on-laptop:        50em;



// Using media queries with like this:
// @include media-query($on-palm) {
//   .wrapper {
//       padding-right: $spacing-unit / 2;
//       padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
      @content;
  }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "formatting",
        "layout",
        "syntax-highlighting"
;
