/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

body > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.page-content {
  flex-grow: 1;
}



/**
 * Basic styling
 */
body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: 300;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}

h1:not(:first-child), h2:not(:first-child), h3:not(:first-child),
h4:not(:first-child), h5:not(:first-child), h6:not(:first-child) {
  margin-top: $spacing-unit * 1.5;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}


/**
 * Images
 */
img {
  image-orientation: from-image;
  max-width: 100%;
  vertical-align: middle;
}

p > img {
  margin: 0.2em 0.5em 1em;
}


/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
     margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}



/**
 * Links
 */
a {
  color: $link-color;
  text-decoration: none;

  &:visited {
    color: $link-visited-color;
  }

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
}



/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  font-size: 18px;
  letter-spacing: -1px;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  font-size: 15px;
  background-color: $highlight-color;
  border-radius: 3px;
  white-space: pre;
}

code {
  padding: 1px 5px;
}

pre {
  line-height: 1;
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }

  > code:only-child {
    display: block;
  }
}

body .highlight {
  .gd {
    background-color: #660000;
    color: inherit;
  }
  .gi {
    background-color: inherit;
    color: #00cc00;
  }

  .language-diff .err {
    background-color: inherit;
    color: inherit;
  }
}

.alpm-hooks .highlight .err {
  background-color: inherit;
  color: inherit;
}


/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:     calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:     calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}



/**
 * Clearfix
 */
%clearfix {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}



/**
 * Icons
 */
.icon {

  > svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    path {
      fill: $grey-color;
    }
  }
}
