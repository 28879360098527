/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: 56px;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  font-size: 26px;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  float: right;
  line-height: 56px;

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the first one
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: 30px;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        width: 18px;
        height: 15px;

        path {
          fill: $grey-color-dark;
        }
      }
    }

    .trigger {
      clear: both;
      display: none;
    }

    &:hover .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:     calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:     calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:     calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:     calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:     calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:     calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.wrapper {
  height: 100%;
}

.page-content {
  padding: $spacing-unit 0;
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    background: linear-gradient(0deg, $background-color, 10%, lighten($background-color, 4%), 90%, $background-color);
    margin-bottom: 2 * $spacing-unit;
    padding: 0.5em;

    > .post-excerpt {
      margin-left: 0.5em;
    }
  }

  h2 {
    margin: 0;
  }
  .post-meta {
    display: block;
    margin: 0 0 1em;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
  margin: 0 1em;

  p {
    margin: 0;
  }
}

.post-link {
  display: block;
  font-size: 24px;
}

ul, ol {
  &.inline {
    list-style: none;

    &, li {
      display: inline;
      margin: 0;
    }

    li:not(:last-child):after {
      content: ',';
    }
  }
}


/**
 * Posts
 */
.post {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  & > * {
    flex-shrink: 0;
    flex-grow: 0;
  }
  .post-content {
    flex-grow: 1;
  }
}

.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}

.post-tags {
  display: inline;
  list-style: none;
  margin: 0 0.5em;

  li {
    display: inline;
  }
}

.post-tag:not(:first-child):before {
  content: '-';
}

.prev-next {
  display: flex;
  flex-flow: row wrap;

  * {
    flex-shrink: 0;
    flex-grow: 1;
  }

  .prev::before {
    content: "<";
  }

  .next {
    text-align: right;
  }

  .next::after {
    content: ">";
  }
}

.license {
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  margin: 2em 0 0 0;
  padding: 0.5em 0 1em;
  opacity: 0.35;

  > * {
  margin: 0;
  padding: 0 0.5em;
  }

  :first-child {
  align-self: center;
  }
}

.license:hover {
  opacity: 1;
}
