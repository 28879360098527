p.poem {
  white-space: pre;
}

p.right {
  text-align: right;
}

div.highlighter-rouge {
  padding: 0 $spacing-unit * 2;
}

.post-content table {
  margin: $spacing-unit auto $spacing-unit * 2;
}


figure {
  padding: 0 $spacing-unit * 2;
  margin: $spacing-unit * 2 0;
}

figure img, figure a:not(figcaption a) {
  display: block;
  margin: 0 auto;
  max-height: 30em;
}

figure figcaption {
  font-style: italic;
  margin-top: $spacing-unit * 2;
  padding: 0 $spacing-unit * 2;
  text-align: center;
}

figure figcaption em, figure figcaption code {
  font-style: normal;
}

figure .images {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  text-align: center;
}

figure .images.height-capped img {
  max-height: $content-width * 0.7;
}

figure .images + .images {
  margin-top: $spacing-unit;
}

/* Only one image */
figure .images > a:first-child:nth-last-child(1) {
  width: 100%;
}

/* Only one image */
figure .images > video:only-child {
  margin: 0 auto;
  max-width: 100%;
}

/* Two images */
figure .images > a:first-child:nth-last-child(2),
figure .images > a:first-child:nth-last-child(2) ~ a {
  width: calc(50% - #{$spacing-unit});
}

/* Three images */
figure .images > a:first-child:nth-last-child(3),
figure .images > a:first-child:nth-last-child(3) ~ a {
  width: calc(33.33% - #{$spacing-unit * 2});
}

/* Four images */
figure .images > a:first-child:nth-last-child(4),
figure .images > a:first-child:nth-last-child(4) ~ a {
  width: calc(25% - #{$spacing-unit * 3});
}
